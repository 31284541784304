#welcome {
  text-align: center;

  .expander {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center; }

  .message {
    flex: 0.5 1;
    margin: auto 0;
    padding: 0 15px;
    max-width: 60rem;
    span {
      display: block;
      margin: 30px 0; } }

  .buttons-bar {
    width: 100%; } }
