#main {
  //padding: 10px 20px
  //width: 500px

  h1 {
    text-align: center;
    margin-bottom: 10px; }

  #file-picker {
    margin-top: 20px; }

  #channel-picker {
    margin-top: 15px; }

  .button.start {
    flex: 1 1 auto;
    max-width: 20em;
    margin: 15px 5px 15px; } }
