#modal-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  &:after {
    content: '';
    flex: 0.7 1 auto;
    display: block; }

  .modal-card {
    display: flex;
    position: relative;
    max-width: 800px;
    max-height: 650px;
    width: 100%;
    height: 100%;
    background: white;
    padding: 15px;
    font-size: 1.8rem; }

  .modal-close {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    font-size: 3.5rem;
    line-height: 3.5rem;
    font-weight: 700;
    padding: 0;
    margin: 10px;
    cursor: pointer;
    background-color: white;
    border-radius: 100%; }

  .modal-content {
    padding: 10px;
    overflow: auto; } }
