#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  padding: 18px 40px 12px;
  border-bottom: 1px solid #e5e5e5;

  .header-image {
    max-height: 100%;
    max-width: 100%; }

  .button-bar {
    display: flex;
    align-items: center;

    .desktop-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0 2.5rem 0 0;
      background: url(../static/icons/desktop-icon.svg);
      background-size: 100%;
      height: 3.2rem;
      width: 3.2rem;
      &:hover {
        filter: drop-shadow(0 3px 5px rgba(83, 112, 128, 0.4)); }
      .desktop-button-alert {
        height: 2rem;
        width: 2rem;
        background-color: #e12c44;
        border-radius: 100%;
        text-align: center;
        color: white;
        line-height: 2rem;
        font-size: 1.4rem;
        font-weight: 700;
        user-select: none; } }

    .help-button {
      height: 2.9rem;
      width: 2.9rem;
      padding: 0;
      margin: 0;
      border: 2px solid darkgrey;
      border-radius: 100%;
      box-sizing: border-box;
      text-align: center;
      color: darkgrey;
      line-height: 2.5rem;
      font-size: 2rem;
      font-weight: 700;
      cursor: pointer;
      user-select: none;
      &:hover {
        box-shadow: 0 3px 10px 0 rgba(83,112,128,.71); } } } }
