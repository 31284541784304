#login {
  text-align: center;
  //width: 485px

  h1 {
    margin-bottom: 30px; }

  form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto; }

  label {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
    &:first-child {
      margin-top: 20px; }

    span {
      display: inline-block;
      text-align: left;
      width: 160px;
      font-size: 1.6rem; }

    input {
      width: 280px;
      max-width: 90%; }

    &.remember {
      span {
        width: auto; }
      input {
        width: 20px; } } }

  //button
  ////flex: 1 1 auto
  ////max-width: 20em
  ////margin: 5px

  .login-message {
    margin-bottom: 25px; }

  .form-body {
    flex: 0.5 1 auto;
    width: 100%;
    margin: auto; }

  .error {
    color: #d14;
    margin-top: 15px; }

  &.unlock {
    //width: 400px
    //button.secondary
    ////margin-left: 10px
    label span {
      width: auto;
      margin-right: 10px; } } }
