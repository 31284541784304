.expand {
  display: flex;
  flex: 1 1 auto; }

#file-picker {

  label {
    display: block;

    span {
      display: block; } }

  button {
    margin-top: 10px; }


  .hidden-file {
    display: none; }

  .selected-file {
    .info-wrapper {
      display: flex;
      align-items: center;
      height: 47px;
      margin: 5px 0; }
    .info {
      border: 1px solid #ccc;
      background-color: #f1f1f1;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      min-width: 0;
      height: 100%;
      font-size: 1.6rem;
      border-radius: 6px;

      .date {
        color: #aaa;
        font-size: 80%;
        margin-left: 8px; }

      .filename {
        flex: 0 1 auto;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis; }

      .date, .filename {
        white-space: nowrap; } }
    button {
      margin: 0 0 0 5px; } }


  .file-preview {
    display: flex;
    flex: 1 1 auto; }

  .file-preview-scanning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.5; }

  .file-preview-info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .file-preview-info-metadata {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0.5;
      margin: auto 0;

      ul {
        flex: 1 1 auto; } }

    .file-metadata {
      margin-left: 30px;
      text-align: right; } }

  label {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    cursor: pointer;

    .upload-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0.75; }

    .file-button {
      background: url('../static/icons/upload-file.svg') no-repeat;
      background-size: contain;
      border: none;
      border-radius: 0;
      width: 70px;
      height: 82px; }

    .file-error {
      color: #d14;
      margin-top: 15px; } }

  .size-alert {
    display: flex;
    line-height: 2rem;
    align-items: center;
    max-width: 65%; }

  .warning-icon {
    color: orange;
    &:before {
      content: '⚠';
      color: orange;
      font-size: 2rem;
      margin-right: 10px; } }

  .error-icon {
    color: red;
    &:before {
      content: '!';
      color: red;
      font-size: 1.8rem;
      font-weight: 700;
      margin-right: 10px;
      border: 1px solid red;
      border-radius: 100%;
      text-align: center;
      width: 2rem; } } }


#drop-area {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border: 10px dashed #ccc;
  border-radius: 20px;
  background-color: #f1f1f1;
  display: flex;
  opacity: 0.8;
  span {
    margin: auto;
    font-size: 200%; } }
