#progress-bar {
  border: 1px #e12c44 solid;
  border-radius: 6px;
  width: 100%;

  .progress-fill {
    background-color: #e12c44;
    height: 30px;
    color: #e8b2b9;
    text-align: center;
    white-space: nowrap; } }
