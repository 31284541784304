#legal {
  display: flex;
  flex-direction: column;
  flex: 0.6 0 auto;
  align-items: flex-end;
  padding: 25px;

  .legal-link {
    margin: auto auto 0;
    cursor: pointer;
    color: #717171;
    &:hover {
      text-decoration: underline; } }

  .app-version {
    color: lightgrey;
    text-align: center;
    font-size: 1.3rem;
    width: 100%;
    margin-top: 0; } }
